{
  "identity": 0,
  "ip4": 4,
  "tcp": 6,
  "sha1": 17,
  "sha2-256": 18,
  "sha2-512": 19,
  "sha3-512": 20,
  "sha3-384": 21,
  "sha3-256": 22,
  "sha3-224": 23,
  "shake-128": 24,
  "shake-256": 25,
  "keccak-224": 26,
  "keccak-256": 27,
  "keccak-384": 28,
  "keccak-512": 29,
  "blake3": 30,
  "dccp": 33,
  "murmur3-128": 34,
  "murmur3-32": 35,
  "ip6": 41,
  "ip6zone": 42,
  "path": 47,
  "multicodec": 48,
  "multihash": 49,
  "multiaddr": 50,
  "multibase": 51,
  "dns": 53,
  "dns4": 54,
  "dns6": 55,
  "dnsaddr": 56,
  "protobuf": 80,
  "cbor": 81,
  "raw": 85,
  "dbl-sha2-256": 86,
  "rlp": 96,
  "bencode": 99,
  "dag-pb": 112,
  "dag-cbor": 113,
  "libp2p-key": 114,
  "git-raw": 120,
  "torrent-info": 123,
  "torrent-file": 124,
  "leofcoin-block": 129,
  "leofcoin-tx": 130,
  "leofcoin-pr": 131,
  "sctp": 132,
  "dag-jose": 133,
  "dag-cose": 134,
  "eth-block": 144,
  "eth-block-list": 145,
  "eth-tx-trie": 146,
  "eth-tx": 147,
  "eth-tx-receipt-trie": 148,
  "eth-tx-receipt": 149,
  "eth-state-trie": 150,
  "eth-account-snapshot": 151,
  "eth-storage-trie": 152,
  "bitcoin-block": 176,
  "bitcoin-tx": 177,
  "bitcoin-witness-commitment": 178,
  "zcash-block": 192,
  "zcash-tx": 193,
  "stellar-block": 208,
  "stellar-tx": 209,
  "md4": 212,
  "md5": 213,
  "bmt": 214,
  "decred-block": 224,
  "decred-tx": 225,
  "ipld-ns": 226,
  "ipfs-ns": 227,
  "swarm-ns": 228,
  "ipns-ns": 229,
  "zeronet": 230,
  "secp256k1-pub": 231,
  "bls12_381-g1-pub": 234,
  "bls12_381-g2-pub": 235,
  "x25519-pub": 236,
  "ed25519-pub": 237,
  "dash-block": 240,
  "dash-tx": 241,
  "swarm-manifest": 250,
  "swarm-feed": 251,
  "udp": 273,
  "p2p-webrtc-star": 275,
  "p2p-webrtc-direct": 276,
  "p2p-stardust": 277,
  "p2p-circuit": 290,
  "dag-json": 297,
  "udt": 301,
  "utp": 302,
  "unix": 400,
  "p2p": 421,
  "ipfs": 421,
  "https": 443,
  "onion": 444,
  "onion3": 445,
  "garlic64": 446,
  "garlic32": 447,
  "tls": 448,
  "quic": 460,
  "ws": 477,
  "wss": 478,
  "p2p-websocket-star": 479,
  "http": 480,
  "json": 512,
  "messagepack": 513,
  "libp2p-peer-record": 769,
  "sha2-256-trunc254-padded": 4114,
  "ripemd-128": 4178,
  "ripemd-160": 4179,
  "ripemd-256": 4180,
  "ripemd-320": 4181,
  "x11": 4352,
  "sm3-256": 21325,
  "blake2b-8": 45569,
  "blake2b-16": 45570,
  "blake2b-24": 45571,
  "blake2b-32": 45572,
  "blake2b-40": 45573,
  "blake2b-48": 45574,
  "blake2b-56": 45575,
  "blake2b-64": 45576,
  "blake2b-72": 45577,
  "blake2b-80": 45578,
  "blake2b-88": 45579,
  "blake2b-96": 45580,
  "blake2b-104": 45581,
  "blake2b-112": 45582,
  "blake2b-120": 45583,
  "blake2b-128": 45584,
  "blake2b-136": 45585,
  "blake2b-144": 45586,
  "blake2b-152": 45587,
  "blake2b-160": 45588,
  "blake2b-168": 45589,
  "blake2b-176": 45590,
  "blake2b-184": 45591,
  "blake2b-192": 45592,
  "blake2b-200": 45593,
  "blake2b-208": 45594,
  "blake2b-216": 45595,
  "blake2b-224": 45596,
  "blake2b-232": 45597,
  "blake2b-240": 45598,
  "blake2b-248": 45599,
  "blake2b-256": 45600,
  "blake2b-264": 45601,
  "blake2b-272": 45602,
  "blake2b-280": 45603,
  "blake2b-288": 45604,
  "blake2b-296": 45605,
  "blake2b-304": 45606,
  "blake2b-312": 45607,
  "blake2b-320": 45608,
  "blake2b-328": 45609,
  "blake2b-336": 45610,
  "blake2b-344": 45611,
  "blake2b-352": 45612,
  "blake2b-360": 45613,
  "blake2b-368": 45614,
  "blake2b-376": 45615,
  "blake2b-384": 45616,
  "blake2b-392": 45617,
  "blake2b-400": 45618,
  "blake2b-408": 45619,
  "blake2b-416": 45620,
  "blake2b-424": 45621,
  "blake2b-432": 45622,
  "blake2b-440": 45623,
  "blake2b-448": 45624,
  "blake2b-456": 45625,
  "blake2b-464": 45626,
  "blake2b-472": 45627,
  "blake2b-480": 45628,
  "blake2b-488": 45629,
  "blake2b-496": 45630,
  "blake2b-504": 45631,
  "blake2b-512": 45632,
  "blake2s-8": 45633,
  "blake2s-16": 45634,
  "blake2s-24": 45635,
  "blake2s-32": 45636,
  "blake2s-40": 45637,
  "blake2s-48": 45638,
  "blake2s-56": 45639,
  "blake2s-64": 45640,
  "blake2s-72": 45641,
  "blake2s-80": 45642,
  "blake2s-88": 45643,
  "blake2s-96": 45644,
  "blake2s-104": 45645,
  "blake2s-112": 45646,
  "blake2s-120": 45647,
  "blake2s-128": 45648,
  "blake2s-136": 45649,
  "blake2s-144": 45650,
  "blake2s-152": 45651,
  "blake2s-160": 45652,
  "blake2s-168": 45653,
  "blake2s-176": 45654,
  "blake2s-184": 45655,
  "blake2s-192": 45656,
  "blake2s-200": 45657,
  "blake2s-208": 45658,
  "blake2s-216": 45659,
  "blake2s-224": 45660,
  "blake2s-232": 45661,
  "blake2s-240": 45662,
  "blake2s-248": 45663,
  "blake2s-256": 45664,
  "skein256-8": 45825,
  "skein256-16": 45826,
  "skein256-24": 45827,
  "skein256-32": 45828,
  "skein256-40": 45829,
  "skein256-48": 45830,
  "skein256-56": 45831,
  "skein256-64": 45832,
  "skein256-72": 45833,
  "skein256-80": 45834,
  "skein256-88": 45835,
  "skein256-96": 45836,
  "skein256-104": 45837,
  "skein256-112": 45838,
  "skein256-120": 45839,
  "skein256-128": 45840,
  "skein256-136": 45841,
  "skein256-144": 45842,
  "skein256-152": 45843,
  "skein256-160": 45844,
  "skein256-168": 45845,
  "skein256-176": 45846,
  "skein256-184": 45847,
  "skein256-192": 45848,
  "skein256-200": 45849,
  "skein256-208": 45850,
  "skein256-216": 45851,
  "skein256-224": 45852,
  "skein256-232": 45853,
  "skein256-240": 45854,
  "skein256-248": 45855,
  "skein256-256": 45856,
  "skein512-8": 45857,
  "skein512-16": 45858,
  "skein512-24": 45859,
  "skein512-32": 45860,
  "skein512-40": 45861,
  "skein512-48": 45862,
  "skein512-56": 45863,
  "skein512-64": 45864,
  "skein512-72": 45865,
  "skein512-80": 45866,
  "skein512-88": 45867,
  "skein512-96": 45868,
  "skein512-104": 45869,
  "skein512-112": 45870,
  "skein512-120": 45871,
  "skein512-128": 45872,
  "skein512-136": 45873,
  "skein512-144": 45874,
  "skein512-152": 45875,
  "skein512-160": 45876,
  "skein512-168": 45877,
  "skein512-176": 45878,
  "skein512-184": 45879,
  "skein512-192": 45880,
  "skein512-200": 45881,
  "skein512-208": 45882,
  "skein512-216": 45883,
  "skein512-224": 45884,
  "skein512-232": 45885,
  "skein512-240": 45886,
  "skein512-248": 45887,
  "skein512-256": 45888,
  "skein512-264": 45889,
  "skein512-272": 45890,
  "skein512-280": 45891,
  "skein512-288": 45892,
  "skein512-296": 45893,
  "skein512-304": 45894,
  "skein512-312": 45895,
  "skein512-320": 45896,
  "skein512-328": 45897,
  "skein512-336": 45898,
  "skein512-344": 45899,
  "skein512-352": 45900,
  "skein512-360": 45901,
  "skein512-368": 45902,
  "skein512-376": 45903,
  "skein512-384": 45904,
  "skein512-392": 45905,
  "skein512-400": 45906,
  "skein512-408": 45907,
  "skein512-416": 45908,
  "skein512-424": 45909,
  "skein512-432": 45910,
  "skein512-440": 45911,
  "skein512-448": 45912,
  "skein512-456": 45913,
  "skein512-464": 45914,
  "skein512-472": 45915,
  "skein512-480": 45916,
  "skein512-488": 45917,
  "skein512-496": 45918,
  "skein512-504": 45919,
  "skein512-512": 45920,
  "skein1024-8": 45921,
  "skein1024-16": 45922,
  "skein1024-24": 45923,
  "skein1024-32": 45924,
  "skein1024-40": 45925,
  "skein1024-48": 45926,
  "skein1024-56": 45927,
  "skein1024-64": 45928,
  "skein1024-72": 45929,
  "skein1024-80": 45930,
  "skein1024-88": 45931,
  "skein1024-96": 45932,
  "skein1024-104": 45933,
  "skein1024-112": 45934,
  "skein1024-120": 45935,
  "skein1024-128": 45936,
  "skein1024-136": 45937,
  "skein1024-144": 45938,
  "skein1024-152": 45939,
  "skein1024-160": 45940,
  "skein1024-168": 45941,
  "skein1024-176": 45942,
  "skein1024-184": 45943,
  "skein1024-192": 45944,
  "skein1024-200": 45945,
  "skein1024-208": 45946,
  "skein1024-216": 45947,
  "skein1024-224": 45948,
  "skein1024-232": 45949,
  "skein1024-240": 45950,
  "skein1024-248": 45951,
  "skein1024-256": 45952,
  "skein1024-264": 45953,
  "skein1024-272": 45954,
  "skein1024-280": 45955,
  "skein1024-288": 45956,
  "skein1024-296": 45957,
  "skein1024-304": 45958,
  "skein1024-312": 45959,
  "skein1024-320": 45960,
  "skein1024-328": 45961,
  "skein1024-336": 45962,
  "skein1024-344": 45963,
  "skein1024-352": 45964,
  "skein1024-360": 45965,
  "skein1024-368": 45966,
  "skein1024-376": 45967,
  "skein1024-384": 45968,
  "skein1024-392": 45969,
  "skein1024-400": 45970,
  "skein1024-408": 45971,
  "skein1024-416": 45972,
  "skein1024-424": 45973,
  "skein1024-432": 45974,
  "skein1024-440": 45975,
  "skein1024-448": 45976,
  "skein1024-456": 45977,
  "skein1024-464": 45978,
  "skein1024-472": 45979,
  "skein1024-480": 45980,
  "skein1024-488": 45981,
  "skein1024-496": 45982,
  "skein1024-504": 45983,
  "skein1024-512": 45984,
  "skein1024-520": 45985,
  "skein1024-528": 45986,
  "skein1024-536": 45987,
  "skein1024-544": 45988,
  "skein1024-552": 45989,
  "skein1024-560": 45990,
  "skein1024-568": 45991,
  "skein1024-576": 45992,
  "skein1024-584": 45993,
  "skein1024-592": 45994,
  "skein1024-600": 45995,
  "skein1024-608": 45996,
  "skein1024-616": 45997,
  "skein1024-624": 45998,
  "skein1024-632": 45999,
  "skein1024-640": 46000,
  "skein1024-648": 46001,
  "skein1024-656": 46002,
  "skein1024-664": 46003,
  "skein1024-672": 46004,
  "skein1024-680": 46005,
  "skein1024-688": 46006,
  "skein1024-696": 46007,
  "skein1024-704": 46008,
  "skein1024-712": 46009,
  "skein1024-720": 46010,
  "skein1024-728": 46011,
  "skein1024-736": 46012,
  "skein1024-744": 46013,
  "skein1024-752": 46014,
  "skein1024-760": 46015,
  "skein1024-768": 46016,
  "skein1024-776": 46017,
  "skein1024-784": 46018,
  "skein1024-792": 46019,
  "skein1024-800": 46020,
  "skein1024-808": 46021,
  "skein1024-816": 46022,
  "skein1024-824": 46023,
  "skein1024-832": 46024,
  "skein1024-840": 46025,
  "skein1024-848": 46026,
  "skein1024-856": 46027,
  "skein1024-864": 46028,
  "skein1024-872": 46029,
  "skein1024-880": 46030,
  "skein1024-888": 46031,
  "skein1024-896": 46032,
  "skein1024-904": 46033,
  "skein1024-912": 46034,
  "skein1024-920": 46035,
  "skein1024-928": 46036,
  "skein1024-936": 46037,
  "skein1024-944": 46038,
  "skein1024-952": 46039,
  "skein1024-960": 46040,
  "skein1024-968": 46041,
  "skein1024-976": 46042,
  "skein1024-984": 46043,
  "skein1024-992": 46044,
  "skein1024-1000": 46045,
  "skein1024-1008": 46046,
  "skein1024-1016": 46047,
  "skein1024-1024": 46048,
  "poseidon-bls12_381-a2-fc1": 46081,
  "poseidon-bls12_381-a2-fc1-sc": 46082,
  "zeroxcert-imprint-256": 52753,
  "fil-commitment-unsealed": 61697,
  "fil-commitment-sealed": 61698,
  "holochain-adr-v0": 8417572,
  "holochain-adr-v1": 8483108,
  "holochain-key-v0": 9728292,
  "holochain-key-v1": 9793828,
  "holochain-sig-v0": 10645796,
  "holochain-sig-v1": 10711332
}